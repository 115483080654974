<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <el-button style="float: right;position: relative;z-index: 1;" v-if="MixinIsFormEnterprise" type="primary"
      @click="handleSaveShopData('ruleForm')" size="small">
      保存
    </el-button>

    <el-form :model="ruleForm" ref="ruleForm" label-width="180px" class="demo-ruleForm" :rules="rules">
      <el-form-item label="站点名称" prop="shop_name">
        <el-input v-if="MixinIsFormEnterprise" v-model="ruleForm.shop_name" style="width: 300px"></el-input>
        <span v-else>{{ ruleForm.shop_name }}</span>
      </el-form-item>
      <el-form-item label="站点后台" prop="region">
        <span>https://{{ ruleForm.domain_name + $url }}</span>
      </el-form-item>
      <el-form-item label="创建时间">
        <span>
          {{ ruleForm.shop_createtime | unixToDate("yyyy-MM-dd hh:mm") }}
        </span>
      </el-form-item>
      <el-form-item v-if="MixinIsFormEnterprise" label="公司名称">
        <el-input v-model="ruleForm.company_name" style="width: 300px" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item v-if="MixinIsFormEnterprise" label="业务员名称">
        <el-input v-model="ruleForm.salesman_name" style="width: 300px" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item v-if="MixinIsFormEnterprise" label="是否需要资质认证">
        <el-switch v-model="ruleForm.qualification_certification_required"
          style="margin-right: 40px"></el-switch>提示：开启后，站点若未完成认证，登录后台时将强制引导进行资质认证。
        <br />
      </el-form-item>

      <el-form-item v-if="MixinIsFormEnterprise" label="商城货币单位设置" prop="monetary_unit">
        <el-input v-model="ruleForm.monetary_unit" style="width: 300px">
          <template slot="prepend">
            <span style="margin-right: 20px">￥</span>
          </template>
          <template slot="prepend">转换为</template>
        </el-input>
      </el-form-item>

      <el-form-item v-if="MixinIsFormEnterprise" label="设置站点后台登录页背景图" prop="background_img">
        <el-upload v-show="ruleForm.background_img == ''" ref="uploadFile" :action="`${MixinUploadApi}?scene=goods`"
          list-type="picture-card" :limit="1" :before-upload="beforeUpload" :on-success="handleUploadSuccess">
          <i class="el-icon-plus"></i>
          <template slot="tip">
            <div style="line-height: 50px">
              请上传16：9，大小不超10MB，格式为jpg/png的图片。
            </div>
          </template>
        </el-upload>
        <div class="background-img" v-if="ruleForm.background_img">
          <img :src="ruleForm.background_img" alt="" />
          <div class="mantle">
            <i class="el-icon-delete" @click="handleUploadRemove"></i>
            <el-button round size="mini" class="btn-change-img" @click="handleChangeImg">更换图片</el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item v-if="MixinIsFormEnterprise" label="商城备注信息">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 7 }" maxlength="100" :rows="2"
          placeholder="请输入100字以内的备注信息" v-model="ruleForm.shop_remark" style="width: 300px"></el-input>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_account from "@/api/account";
import XAddressSelect from "../../components/x-address-select/x-address-select";
import * as API_Login from "@/api/login";
export default {
  name: "person",
  components: { XAddressSelect },
  data () {
    return {
      MixinIsFormEnterprise: false,
      params: {
        shop_id: undefined,
        shop_name: undefined,
        domain_name: undefined,
        shop_createtime: undefined,
        monetary_unit: undefined,
        region: undefined,
        show_price: undefined,
        goods_sale_price_enable: undefined,
        manual_exchange_goods: undefined,
        category_button: undefined,
        third_category_button: undefined,
        enable_aftersale: undefined,
        allow_member_new_register: undefined,
        shop_remark: undefined,
        qualification_certification_required: undefined,
        company_name: undefined,
        salesman_name: undefined
      },
      ruleForm: {
        shop_id: undefined,
        shop_name: undefined,
        domain_name: undefined,
        shop_createtime: undefined,
        monetary_unit: undefined,
        category_button: undefined,
        show_price: undefined,
        goods_sale_price_enable: undefined,
        manual_exchange_goods: undefined,
        category_button: undefined,
        third_category_button: undefined,
        enable_aftersale: undefined,
        allow_member_new_register: false,
        regionValue: [],
        // task 691新加
        background_img: "",
        btn_color: undefined,
        third_category_button: false,
        shop_remark: undefined,
        qualification_certification_required: undefined,
        company_name: undefined,
        salesman_name: undefined
      },
      rules: {
        shop_name: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        salesman_name: [
          {
            required: true,
            message: "请输入业务员名称",
            trigger: "blur",
          },
        ],
        monetary_unit: [
          {
            min: 1,
            max: 2,
            message: "字数不超2个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created () {
    this.getShopMsg();
    API_Login.getLoginType().then((res) => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem("MixinIsFormEnterprise", true);
      } else sessionStorage.setItem("MixinIsFormEnterprise", false);
    });
  },
  methods: {
    handleSwitch (e, name) {
      this.ruleForm[name] = e;
      this.$set(this, "ruleForm", { ...this.ruleForm })
    },
    //保存
    handleSaveShopData (formName) {
      this.params = {};
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.params.shop_id = this.ruleForm.shop_id;
          this.params.shop_name = this.ruleForm.shop_name;
          this.params.qualification_certification_required = this.ruleForm.qualification_certification_required ? 1 : 0;
          this.params.shop_remark = this.ruleForm.shop_remark;
          this.params.monetary_unit = this.ruleForm.monetary_unit;
          this.params.btn_color = this.ruleForm.btn_color;
          this.params.background_img = this.ruleForm.background_img;
          this.params.salesman_name = this.ruleForm.salesman_name;
          this.params.company_name = this.ruleForm.company_name;
          if (this.ruleForm.regionValue) {
            this.params.region =
              this.ruleForm.regionValue[this.ruleForm.regionValue.length - 1];
          }
          let swKeys = [
            "goods_sale_price_enable",
            "manual_exchange_goods",
            "category_button",
            "third_category_button",
            "enable_aftersale",
            "allow_member_new_register"
          ]
          swKeys.forEach(key => {
            this.params[key] = +this.ruleForm[key]
          })

          API_account.editShopSetting(this.params).then((res) => {
            this.getShopMsg();
            this.$message.success("操作成功");
          });
        } else {
          return false;
        }
      });
    },
    //查询
    getShopMsg () {
      API_account.getShopmsg().then((res) => {
        if (res.background_img == null) {
          res.background_img = "";
        }
        Object.keys(this.ruleForm).forEach((key) => {
          let value = res[key];

          if (key === 'qualification_certification_required') {
            value = res.qualification_certification_required === 1;
          }
          if (key === "category_button") {
            value = res.category_button === 1;
          }

          // 移动端三级分类按钮权限
          if (key === "third_category_button") {
            value = res.third_category_button == 1;
          }

          if (key === "show_price") {
            value = res.show_mktprice === 1;
          }

          if (key === "show_mktprice") {
            value = res.show_price === 1;
          }

          if (key === "enable_aftersale") {
            value = res.enable_aftersale === 1;
          }

          let swKeys = ["show_mktprice",
            "goods_sale_price_enable",
            "manual_exchange_goods",
            "category_button",
            "third_category_button",
            "enable_aftersale",
            "allow_member_new_register"
          ]
          if (swKeys.includes(key)) {
            value = res[key] === 1
          }

          if (!value && key === "regionValue") {
            if (res.web_shop_address) {
              value = [
                res.web_shop_address.province_id,
                res.web_shop_address.city_id,
                res.web_shop_address.county_id,
                res.web_shop_address.town_id,
              ].filter((id) => !!id);
            }
          }

          this.ruleForm[key] = value;
        });

        this.ruleForm = {
          ...this.ruleForm,
        };
        this.$forceUpdate();
      });
    },
    // 上传图片
    // 上传之前校验
    beforeUpload (file) {
      let result = true;
      const fileType = file.name.split(".")[1];
      const fileSize = parseFloat((file.size / 1024 / 1024).toFixed(2));
      if (fileType !== "jpg" && fileType !== "png") {
        result = false;
      }
      if (fileSize > 10) {
        result = false;
      }
      if (!result) {
        this.$message.error("请按照提示信息上传图片");
        return false;
      }
    },
    // 上传成功回调
    handleUploadSuccess (response, file, fileList) {
      this.ruleForm.background_img = response.url;
    },
    // 删除文件
    handleUploadRemove () {
      this.ruleForm.background_img = "";
      this.$refs.uploadFile.clearFiles();
    },
    // 更换图片
    handleChangeImg () {
      this.$refs.uploadFile.clearFiles();
      this.$refs.uploadFile.$children[1].$refs.input.click();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__content {
  .background-img {
    width: 167px;
    height: 167px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .mantle {
      width: 167px;
      height: 167px;
      display: none;
      position: absolute;
      top: 0;
      background-color: black;
      opacity: 0.5;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 23px;
        position: relative;
        left: 85%;
      }

      .btn-change-img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: 15%;
        background-color: black;
        opacity: 0.6;
        color: white;
      }
    }
  }

  .background-img:hover {
    .mantle {
      display: block;
    }
  }

  .el-color-picker {
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;

    .el-color-picker__trigger {
      width: 90px;
      height: 30px;
      padding: 0;
      border: 0;
      border-radius: 5px;
    }

    .el-color-picker__color {
      border: 1px solid #cccccc;
    }

    .el-icon-arrow-down:before {
      content: "";
    }
  }
}

.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}

.shop-status-warper {
  .switch-table {
    width: 100%;
    text-align: center;
    border: 1px solid rgb(146, 147, 149);

    &:not(:last-of-type) {
      border-bottom: 0;
    }

    th {
      padding: 5px 5px;
      width: 20%;
      background-color: rgb(26, 67, 169);
      color: #fff;
      border-color: rgb(165, 185, 238);
      border-right: 1px solid rgb(165, 185, 238);
      border-bottom: 0;

      &:last-of-type {
        border-right: 0;
      }
    }

    td {
      border-right: 1px solid rgb(146, 147, 149);
      border-bottom: 0;

      &:last-of-type {
        border-right: 0;
      }
    }
  }

}
</style>
